import useClientMutation from '../../common/useClientMutation';
import { SITE_USER_URL } from '../../consts';

import type {
  UpdateReadingListInput,
  UpdateReadingListOutput,
} from '@haaretz/s-data-structure-types';

export default function useUpdateReadingList() {
  return useClientMutation<UpdateReadingListOutput, UpdateReadingListInput>({
    cacheKey: 'updateReadingList',
    url: SITE_USER_URL,
    clientOptions: { cacheTime: 0, retry: false },
    fetchOptions: { method: 'GET' },
  });
}
