'use client';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import { widthBpsInEm } from '@haaretz/l-theme-shared/lib/consts';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Button from '@haaretz/s-button';
import ClickArea from '@haaretz/s-click-area';
import HtzImage from '@haaretz/s-htz-image';
import Icon from '@haaretz/s-icon';
import Modal from '@haaretz/s-modal';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import * as React from 'react';
import s9 from 'style9';

import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

const c = s9.create({
  base: {
    backgroundColor: fork({ default: color('primary200'), tm: color('neutral300') }),
    color: color('neutral1200'),
    border: 'none',
    overflow: 'visible',
    padding: 0,

    '::backdrop': {
      '--bgc': color('rainbowModalMobileBackdrop', { opacity: fork({ default: 0.8, tm: 0.9 }) }),
      backgroundColor: 'var(--bgc)',
      animationName: s9.keyframes({
        '0%': { backgroundColor: 'transparent' },
        '100%': { backgroundColor: 'var(--bgc)' },
      }),
      animationPlayState: 'running',
      animationDuration: '0.25s',
      animationDirection: 'normal',
      animationFillMode: 'forwards',
      padding: 0,
    },

    ...merge(
      mq({
        until: 's',
        value: {
          marginBottom: 0,
          minWidth: '100%',
          borderTopLeftRadius: space(4),
          borderTopRightRadius: space(4),
        },
      }),
      mq({
        from: 's',
        value: {
          borderTopLeftRadius: space(16),
          borderTopRightRadius: space(16),
          borderBottomLeftRadius: space(16),
          borderBottomRightRadius: space(16),

          '::backdrop': {
            '--bgc': color('rainbowModalBackdrop', { opacity: 0.75 }),
          },
        },
      }),
      mq({
        from: 's',
        until: 'xl',
        value: {
          width: space(101),
        },
      }),
      mq({
        from: 'xl',
        value: {
          width: space(112),
        },
      })
    ),
  },
  onOpenKeyFrames: {
    '--trsfrm-a': 'translateY(120%)',
    '--trsfrm-b': 'translateY(0)',

    animationName: s9.keyframes({
      '0%': { opacity: 0, transform: 'var(--trsfrm-a)' },
      '30%': { opacity: 0 },
      '100%': { opacity: 1, transform: 'var(--trsfrm-b)' },
    }),
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in-out',
    opacity: 0,

    ...merge(
      mq({
        from: 's',
        value: {
          '--trsfrm-a': 'scale(0.7)',
          '--trsfrm-b': 'scale(1)',
        },
      })
    ),
  },
  readingVariant: {
    backgroundColor: fork({
      default: color('primary1200'),
      tm: color('featureBlockTmReadingBg'),
    }),
    color: color('featureBlockTmReadingFg'),
  },
  closeButton: {
    position: 'absolute',
    width: space(4),
    height: space(4),
    color: color('neutral1200'),

    ...merge(
      mq({
        until: 's',
        value: {
          marginTop: space(5),
          insetInlineEnd: space(4),
        },
      }),
      mq({
        from: 's',
        until: 'xl',
        value: {
          marginTop: space(15),
          insetInlineEnd: space(11),
        },
      }),
      mq({
        from: 'xl',
        value: {
          marginTop: space(18),
          insetInlineEnd: space(16),
        },
      })
    ),
  },
  closeIcon: {
    fontSize: space(6.5),
    color: color('neutral1200'),
  },
  closeIconReadingVariant: {
    color: color('featureBlockTmReadingFg'),
  },
  content: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: space(20),
    paddingBottom: space(20),
    width: space(66),

    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          paddingTop: space(28),
          paddingBottom: space(22),
          width: space(74),
        },
      }),
      mq({
        from: 'xl',
        value: {
          paddingTop: space(31),
          paddingInlineStart: space(4),
          paddingInlineEnd: space(4),
          minWidth: space(82),
          paddingBottom: space(24),
        },
      })
    ),
  },
  title: {
    textAlign: 'center',
    fontWeight: 400,

    ...typesetter(4),
    ...merge(
      mq({
        from: 's',
        until: 'xxl',
        value: {
          ...typesetter(5),
        },
      })
    ),
  },
  subtitle: {
    textAlign: 'center',
    paddingTop: space(3),
    ...typesetter(2),

    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: { ...typesetter(3) },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          paddingTop: space(3),
          ...typesetter(3),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          paddingTop: space(4),
          ...typesetter(2),
        },
      })
    ),
  },
  button: {
    marginTop: space(5),
    minWidth: space(19),
    justifyContent: 'center',

    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          marginTop: space(6),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          marginTop: space(7),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          marginTop: space(8),
        },
      })
    ),
  },
  picture: {
    position: 'absolute',
  },
  img: {
    pointerEvents: 'none',
    minWidth: '100%',
    position: 'absolute',
    zIndex: zIndex('below'),
    bottom: 0,

    ...merge(
      mq({
        from: 's',
        value: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }),
      mq({
        from: 's',
        until: 'xl',
        value: {
          marginTop: space(-3),
          minWidth: space(144),
        },
      }),
      mq({
        from: 'xl',
        value: {
          marginTop: space(-1),
          minWidth: space(160),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          marginTop: space(-2),
        },
      })
    ),
  },
});

type FeatureType = 'zen' | 'reading' | 'print';

const imgWidths: number[] = [320, 380, 420, 500];
const imgSizes: React.ComponentProps<typeof HtzImage>['sizes'] = [{ size: '100vw' }];

export interface FeatureBlockModalProps {
  inlineStyle?: InlineStyles;
  styleExtend?: StyleExtend;
  type: FeatureType;
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  onClose?: () => void;
  ['data-testid']?: string;
}

const popupText = {
  reading: {
    title: fork({
      default: 'שמירת כתבות זמינה למנויים בלבד',
      hdc: 'שמירת כתבות זמינה למנויים בלבד',
    }),
    subtitle: fork({
      default: 'ללא פרסומות ובהגשה נוחה לקריאה',
      hdc: 'ללא פרסומות ובהגשה נוחה לקריאה',
    }),
  },
  zen: {
    title: fork({
      default: 'קריאת זן זמינה למנויים בלבד',
      hdc: 'קריאת זן זמינה למנויים בלבד',
    }),
    subtitle: fork({
      default: 'ללא פרסומות ובהגשה נוחה לקריאה',
      hdc: 'ללא פרסומות ובהגשה נוחה לקריאה',
    }),
  },
  print: {
    title: fork({
      default: 'הדפסת כתבה זמינה למנויים בלבד',
      hdc: 'הדפסת כתבה זמינה למנויים בלבד',
    }),
    subtitle: fork({
      default: 'ללא פרסומות ותמונות, ובהגשה נוחה להדפסה',
      hdc: 'ללא פרסומות ותמונות, ובהגשה נוחה להדפסה',
    }),
  },
};

const buttonText = fork({
  default: 'לרכישת מינוי',
  hdc: 'Subscribe',
});

const featureMap: Record<FeatureType, string> = {
  zen: 'Zen',
  reading: 'Reading',
  print: 'Printer Friendly Popup',
};

const promotionUrl = `https://promotion.${fork({
  default: 'haaretz.co.il',
  tm: 'themarker.com',
  hdc: 'haaretz.com',
})}/offers?htm_source=site&htm_medium=pop_up`;

const img: ImageFragment = {
  __typename: 'htz_image_Image',
  alt: '',
  files: [
    {
      path: fork({
        default: '10/ec/c2fc0e46449392a8722c6642cecb/htzmobile.png',
        tm: 'e8/18/71aee679447bbf8ff6797ea3b0b7/tmmobile.png',
      }),
      __typename: 'ImageViewModel_ImageFile',
      width: 1125,
      height: 1000,
      crops: {
        __typename: 'ImageCrops',
      },
    },
  ],
  type: 'image',
  caption: '',
  credit: '',
  link: fork({
    default:
      'https://img.haarets.co.il/bs/0000018c-0b6e-de3d-af9e-0bff6a230001/10/ec/c2fc0e46449392a8722c6642cecb/htzmobile.png',
    tm: 'https://img.haarets.co.il/bs/0000018c-0b76-d2be-a1af-cbf743430001/e8/18/71aee679447bbf8ff6797ea3b0b7/tmmobile.png',
  }),
  url: fork({
    default:
      'https://img.haarets.co.il/bs/0000018c-0b6e-de3d-af9e-0bff6a230001/10/ec/c2fc0e46449392a8722c6642cecb/htzmobile.png',
    tm: 'https://img.haarets.co.il/bs/0000018c-0b76-d2be-a1af-cbf743430001/e8/18/71aee679447bbf8ff6797ea3b0b7/tmmobile.png',
  }),
  photographer: '',
  contentId: fork({
    default: '0000018c-0b6e-de3d-af9e-0bff6a230001',
    tm: '0000018c-0b76-d2be-a1af-cbf743430001',
  }),
};

export default function FeatureBlockModal({
  inlineStyle,
  styleExtend = [],
  type,
  isOpen,
  onToggle,
  onClose,
  'data-testid': dataTestId,
}: FeatureBlockModalProps) {
  const modalRef = React.useRef(null);
  const biAction = useBi();
  const urlWithCampaign = `${promotionUrl}&htm_campaign=${featureMap[type]}`;

  const BI_BASE_DATA = {
    feature: featureMap[type],
    feature_type: 'Content',
    campaign_name: type === 'print' ? 'Printer Friendly' : featureMap[type],
    campaign_details: buttonText,
  };

  useImpressionObserver({
    biData: BI_BASE_DATA,
    elementRef: modalRef,
  });
  const {
    caption,
    credit,
    type: imgType,
    photographer,
    contentId = '',
    url: imageUrl,
    alt = '',
    files,
  } = img;

  return (
    <Modal
      isOpen={isOpen}
      onToggle={onToggle}
      onClose={onClose}
      ref={modalRef}
      labelledBy={`feature-block-modal-title-${type}`}
      aria-describedby={`feature-block-modal-subtitle-${type}`}
      styleExtend={[
        c.base,
        isOpen && c.onOpenKeyFrames,
        type === 'reading' && c.readingVariant,
        ...styleExtend,
      ]}
      inlineStyle={inlineStyle}
      data-testid={dataTestId}
    >
      <picture>
        <source
          media={`(min-width:${widthBpsInEm.s}em)`}
          srcSet={fork({
            default:
              'https://img.haarets.co.il/bs/0000018c-0b74-d2be-a1af-cbf5803e0001/10/23/6d9f8fc741a39ab68e594cc6fe87/htzdesktop.png 1926w',
            tm: 'https://img.haarets.co.il/bs/0000018c-0b75-d4d0-addf-4fff24360001/69/41/192f5e214bd6bfa5e291dc3c5097/tmdesktop.png 1926w',
          })}
          sizes={`(min-width:${widthBpsInEm.s}em) 1926px`}
          width="1926"
          height="1665"
        />
        <HtzImage
          caption={caption}
          sizes={imgSizes}
          credit={credit}
          type={imgType}
          photographer={photographer}
          contentId={contentId || ''}
          url={imageUrl}
          alt={alt || ''}
          aria-hidden
          imgData={files[0]}
          widths={imgWidths}
          aspect="fullimage"
          styleExtend={[c.img]}
        />
      </picture>
      <ClickArea styleExtend={[c.closeButton]} onClick={onClose}>
        <Icon
          icon="close"
          styleExtend={[c.closeIcon, type === 'reading' && c.closeIconReadingVariant]}
        />
      </ClickArea>
      <div className={s9(c.content)}>
        <h2 className={s9(c.title)} id={`feature-block-modal-title-${type}`}>
          {popupText[type].title}
        </h2>
        <p className={s9(c.subtitle)} id={`feature-block-modal-subtitle-${type}`}>
          {popupText[type].subtitle}
        </p>
        <Button
          as="a"
          href={urlWithCampaign}
          variant="sales"
          styleExtend={[c.button]}
          autofocus="true"
          onClick={() => {
            biAction({
              ...BI_BASE_DATA,
              action_id: 3,
            });
          }}
        >
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
}
