import config from '@haaretz/l-config';
import buildBaseImgSrc from '@haaretz/s-image-utils/buildBaseImgSrc';
import buildBaseImgSrcset from '@haaretz/s-image-utils/buildBaseImgSrcset';
import buildSizesString from '@haaretz/s-image-utils/buildSizesString';
import getAspectData from '@haaretz/s-image-utils/getAspectData';
import s9 from 'style9';

import type { ImageAspect } from '@haaretz/s-fragments/gql-types';
import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { SizesType } from '@haaretz/s-image-utils/buildSizesString';
import type { InlineStyles, StyleExtend } from '@haaretz/s-types';
import type { JSX } from 'react';

const c = s9.create({
  base: {},
});

export type NativeSourceProps = Pick<JSX.IntrinsicElements['source'], 'srcSet' | 'media'>;

export type HtzSourceElementProps = NativeSourceProps &
  Pick<ImageFragment, 'contentId'> & {
    inlineStyle?: InlineStyles;
    styleExtend?: StyleExtend;
    imgData: ImageFragment['files'][number];
    aspect: ImageAspect;
    height?: number;
    media: string;
    width?: number;
    widths: Array<number>;
    heights?: Array<number>;
    sizes: SizesType;
    type?: 'avif' | 'webp';
    envOverride?: TEnv;
  };

export default function HtzSource({
  inlineStyle,
  styleExtend = [],
  imgData,
  aspect,
  media,
  contentId,
  widths,
  heights,
  sizes,
  type,
  envOverride,
}: HtzSourceElementProps) {
  const env = envOverride || config.get('env');
  const minWidth = Math.min(...widths);

  const minWidthIndex = widths.findIndex(num => num === minWidth);
  const matchingHeightToMinWidth = heights?.[minWidthIndex];

  const { isCropped, aspectRatio, aspectData } = getAspectData(aspect, imgData);

  const srcUrl = buildBaseImgSrc({
    aspect,
    aspectData,
    aspectRatio,
    contentId,
    env,
    height: matchingHeightToMinWidth,
    imgData,
    isCropped,
    width: minWidth,
  });
  const sizesString = sizes && buildSizesString(sizes);
  const srcSetUrl = buildBaseImgSrcset({ src: srcUrl, widths, aspectRatio, heights, env });

  const { height, width } = aspectData ?? { width: imgData.width, height: imgData.height };
  return (
    <source
      className={s9(c.base, ...styleExtend)}
      style={inlineStyle}
      sizes={sizesString}
      width={width}
      height={height}
      type={type ? `image/${type}` : undefined}
      media={media}
      srcSet={srcSetUrl || srcUrl}
    />
  );
}
