import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import fetchUtility from '@haaretz/s-fetch-utility';
import mergeURLSearchParams from '@haaretz/s-navigation-utils/mergeURLSearchParams';
import { useMutation } from '@tanstack/react-query';

import type { CircularObjectType } from '@haaretz/s-types';
import type { UseMutationOptions } from '@tanstack/react-query';

/**
 * To be used with `useClientMutation` to indicate which mutation is being used.
 * When adding a new mutation, add a new key here
 */

export type ClientMutationCacheKey =
  | 'submitNewVote'
  | 'submitNewComment'
  | 'getUserNickname'
  | 'reportAbuse'
  | 'submitCommentNotificationEmail'
  | 'setUserNickname'
  | 'rejectComment'
  | 'selectEditorsPick'
  | 'updateReadingList'
  | 'tagAlertsRegistration'
  | 'userAlertsRegistration'
  | 'useUserAlertsUnsubscribe'
  | 'NewsletterSignupPersonal';

interface ClientMutationProps<
  TData,
  TVariables extends CircularObjectType<
    string,
    string | number | boolean | undefined | null
  > = never,
  TError = Error,
> {
  url: string;
  cacheKey: ClientMutationCacheKey;
  clientOptions?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationKey' | 'mutationFn'>;
  fetchOptions?: Omit<RequestInit, 'body'>;
  searchParams?: URLSearchParams;
  body?: TVariables;
}

export default function useClientMutation<
  TData extends object,
  TVariables extends CircularObjectType<
    string,
    string | number | boolean | undefined | null
  > = never,
  TError = Error,
>({
  url,
  body,
  clientOptions,
  fetchOptions,
  searchParams,
  cacheKey,
}: ClientMutationProps<TData, TVariables, TError>) {
  const isGetRequest = fetchOptions?.method === 'GET';

  let fetchSearchParam = mergeURLSearchParams(
    body ? new URLSearchParams(discardNullableValues(body as Record<string, string>)) : undefined,
    searchParams,
    { mergeMethod: 'set' }
  );

  return useMutation<TData, TError, TVariables>(
    [
      'all',
      cacheKey,
      url,
      fetchSearchParam?.toString(),
      ...(body === undefined ? [] : [body]),
    ].filter(Boolean),
    async (mutationBody: TVariables) => {
      fetchSearchParam = mergeURLSearchParams(
        mutationBody && isGetRequest
          ? new URLSearchParams(discardNullableValues(mutationBody as Record<string, string>))
          : undefined,
        fetchSearchParam,
        { mergeMethod: 'set' }
      );
      return await fetchUtility<TData, typeof body>({
        url,
        searchParams: fetchSearchParam,
        ...(isGetRequest ? { method: 'GET' } : { method: 'POST', body: mutationBody || body }),
        options: {
          cache: 'no-cache',
        },
      })({});
    },
    {
      ...clientOptions,
      useErrorBoundary: false,
    }
  );
}
