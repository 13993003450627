import config from '@haaretz/l-config';

const brightspotUrl = config.get('brightspot');
const alertsUrl = config.get('alertsUri');

export const COMMENTS_URL = `${brightspotUrl}/srv/comments`;
export const SITE_USER_URL = `${brightspotUrl}/srv/siteUser`;
export const ALERTS_UNSUBSCRIBE_URL = `${alertsUrl}/unsubscribe`;
export const ALERTS_REGISTERED_URL = `${alertsUrl}/register`;
export const USER_RECOMMENDATIONS = `${alertsUrl}/user-recommendations`;
export const USER_REGISTRATION_ELEMENT = `${alertsUrl}/registration-element`;
export const USER_IS_SUBSCRIBED = `${alertsUrl}/is-subscribed`;

export const PERSONALIZATION_URL = `${brightspotUrl}/srv/personalization`;
