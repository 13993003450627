import useClientQuery from '../../common/useClientQuery';
import { SITE_USER_URL } from '../../consts';

import type { CheckArticlePersonalOutput } from '@haaretz/s-data-structure-types';

const CACHE_TIME = 1000 * 10; // 10 seconds

export default function useCheckArticlePersonal(
  { articleId }: { articleId: string },
  { enabled }: { enabled?: boolean }
) {
  const params = new URLSearchParams({
    articleId,
    action: 'checkArticle',
  });

  return useClientQuery<CheckArticlePersonalOutput>({
    cacheKey: 'checkArticleInReadingList',
    url: SITE_USER_URL,
    searchParams: params,
    clientOptions: {
      useErrorBoundary: false,
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
      retry: false,
      enabled,
    },
  });
}
